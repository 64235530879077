import NextImageComponent, { ImageProps } from 'next/image';
import { useState, useMemo, SyntheticEvent, CSSProperties } from 'react';

import ImageStyle from './style';

import { getAssetUrl } from 'src/helpers/utils';

type ImagePropsType = {
  src?: string;
  srcSet?: string;
  srcSetWebp?: string;
  alt: string;
  errorImage?: string;
  style?: CSSProperties;
  className?: string;
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
};

const Image = ({
  src,
  srcSet,
  srcSetWebp,
  alt,
  errorImage = getAssetUrl('thumbnail-course-card.jpg'),
  style,
  className,
}: ImagePropsType) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isImageError, setIsImageError] = useState(false);

  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  const handleImageLoadedError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    if (!isImageError) {
      e.currentTarget.src = errorImage || '';
      setIsImageError(true);
    }
  };

  return useMemo(
    () => (
      <ImageStyle isHeight={className === 'img'}>
        <picture>
          <source srcSet={!isImageError ? srcSetWebp : errorImage} type="image/webp" />
          <source srcSet={!isImageError ? srcSet : errorImage} />
          <img
            loading="lazy"
            className={className}
            draggable={false}
            src={src || getAssetUrl('thumbnail-course-card.jpg')}
            onLoad={handleImageLoaded}
            onError={handleImageLoadedError}
            alt={alt}
            style={style}
          />
        </picture>
        {isImageError && !errorImage && (
          <div className="img-error">
            <span>No Image</span>
          </div>
        )}
      </ImageStyle>
    ),
    [isLoading, isImageError, src],
  );
};

export type NextImageProps = ImageProps & {
  errorImage?: string;
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  objectPosition?: string;
};

export const NextImage = ({
  src = getAssetUrl('thumbnail-course-card.jpg'),
  alt,
  errorImage = getAssetUrl('thumbnail-course-card.jpg'),
  className,
  layout,
  width,
  height,
  objectFit,
  objectPosition,
  onLoad,
  onLoadingComplete,
}: NextImageProps) => {
  const [image, setImage] = useState(src);
  return (
    <NextImageComponent
      width={width}
      height={height}
      src={image || errorImage!}
      alt={alt}
      layout={layout}
      onError={() => setImage(errorImage!)}
      className={className}
      onLoad={onLoad}
      onLoadingComplete={onLoadingComplete}
      objectFit={objectFit}
      objectPosition={objectPosition}
    />
  );
};

export default Image;
